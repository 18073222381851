import { createApp } from 'vue';
import InstantSearch from "vue-instantsearch/dist/vue3/es";
import VueGtag from "vue-gtag";

import App from './App.vue';
import router from './router';

import './index.css';
import './assets/tailwind.css';

const app = createApp(App);
app.use(InstantSearch);
app.use(router);

// enable auto tracking (google analytics)
app.use(VueGtag, {
  config: { 
    id: "G-M43N14VREW",
  },
}, router);

app.mount("#app");
