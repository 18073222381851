<template>
  <div class="max-w-7xl my-4 mx-auto sm:px-6 lg:px-8 px-4">
    <ais-instant-search
      :search-client="searchClient"
      :stalled-search-delay="1000"
      index-name="papers"
      :routing="routing"
      ref="searchBox"
    >
      <ais-configure 
        :distinct="1"
        :hits-per-page.camel="10" />
      <div class="search-panel">
        <div class="search-panel__results">
          <ais-search-box>
            <template v-slot="{ currentRefinement, refine }">
              <div class="mt-1 mb-4 relative rounded-md">
                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <div class="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
                    <SearchIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </div>
                </div>
                <input @input="refine($event.currentTarget.value)"
                  :value="currentRefinement"
                  type="search" name="search" id="search"
                  placeholder="Search"
                  class="pl-10 text-xl font-extrabold focshadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300 px-4 rounded-full"
                  />
              </div>
            </template>
          </ais-search-box>
          <ais-state-results>
            <template v-slot="{ state: { query } }">
              <!-- Only display results and pagination if query is not empty --> 
              <template v-if="query.length">
                <ais-stats class="px-1"/>
                <ais-hits>
                  <template v-slot="{ items }">
                    <ul role="list" class="divide-y divide-gray-200">
                      <li v-for="item in items" :key="item.objectID" class="px-1 py-4">
                        <div class="flex flex-wrap-reverse justify-between">
                          <!-- Paper title and authors -->
                          <div class="min-w-0 max-w-85">
                            <div class="flex space-x-3">
                              <a :href="item.link" target="_blank">
                                <h2 class="article-title">
                                  {{ item.title }}
                                </h2>
                              </a>
                            </div>
                          </div>
                          <!-- Paper meta info -->
                          <div class="sm:flex flex-col flex-shrink-0 items-end align-top mt-1 text-right">
                            <p class="flex items-center space-x-4">
                              <img v-if="item.journal === 'arxiv'" class="block h-4 w-auto" src="@/assets/arxiv-logo.png" alt="arxiv" />
                              <img v-else-if="item.journal === 'biorxiv'" class="block h-4 w-auto" src="@/assets/biorxiv-logo.png" alt="biorxiv" />
                              <img v-else-if="item.journal === 'medrxiv'" class="block h-4 w-auto" src="@/assets/medrxiv-logo.png" alt="medrxiv" />

                              <a :href="item.pdf_link" target="_blank" class="text-sm text-gray-500 hover:text-gray-900 font-medium">
                                <span class="mt-2">PDF</span>
                                
                              </a>
                              <a :href="item.link" target="_blank" class="text-sm text-gray-500 hover:text-gray-900 font-medium">
                                Visit site
                              </a>
                              <!--
                              <button type="button" class="rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                <StarIcon class="text-gray-300 hover:text-gray-400 h-5 w-5" aria-hidden="true" />
                              </button>
                              -->
                            </p>
                          </div>
                        </div>
                        <div class="flex flex-wrap-reverse justify-between">
                          <!-- Paper title and authors -->
                          <div class="min-w-0">
                            <div class="flex space-x-3">
                              <!-- Authors -->
                              <p class="text-gray-900 font-medium text-base space-x-2">
                                <template
                                  v-for="(fieldValue, index) in item['authors']"
                                  :key="`highlight-${fieldValue}-${index}`">
                                    <span class="author"> {{ item.authors[index] }} </span>
                                    <template v-if="index < item['authors'].length - 1">
                                      <span aria-hidden="true">&middot; </span>
                                    </template>
                                </template>
                              </p>
                            </div>
                          </div>
                          <!-- Paper meta info -->
                          <div class="sm:flex flex-col flex-shrink-0 items-end align-top mt-1">
                            <p class="flex items-center space-x-4">
                              <a :href="item.doi_link" target="_blank" class="text-sm text-gray-500 hover:text-gray-900 font-medium">
                                {{ item.doi }}
                              </a>
                            </p>
                          </div>
                        </div>
                        <div class="hit-abstract break-all border-l-4 pl-4 mt-2">
                          {{ item.abstract }}
                        </div>
                      </li>
                    </ul>
                  </template>
                </ais-hits>
                <div class="pagination">
                  <ais-pagination>
                    <template v-slot="{ currentRefinement, pages, isFirstPage, isLastPage, refine, createURL}">
                      <nav class="border-t border-gray-200 px-4 flex items-center justify-between sm:px-0">
                        <div class="-mt-px w-0 flex-1 flex">
                          <!-- Navigate to previous page -->
                          <a v-if="!isFirstPage"
                            :href="createURL(currentRefinement - 1)"
                            @click.prevent="refine(currentRefinement - 1)"
                            class="border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300">
                            <ArrowNarrowLeftIcon class="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                            Previous
                          </a>
                        </div>
                        <div class="hidden md:-mt-px md:flex">
                          <template v-for="page in pages" :key="page">
                            <a
                              :href="createURL(page)"
                              class="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium"
                              :class="(page === currentRefinement) ? 'border-indigo-500 text-indigo-600 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium' : ''"
                              @click.prevent="refine(page)"
                            >
                              {{ page + 1 }}
                            </a>
                          </template>
                        </div>
                        <div class="-mt-px w-0 flex-1 flex justify-end">
                          <a v-if="!isLastPage"
                            :href="createURL(currentRefinement + 1)"
                            @click.prevent="refine(currentRefinement + 1)"
                            class="border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300">
                            Next
                            <ArrowNarrowRightIcon class="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                          </a>
                        </div>
                      </nav>
                    </template>
                  </ais-pagination>
                </div>
              </template>
              <template v-else>
                <p class="text-center mb-4 content">For example, search for <b><a href="/?query=rna%20sequencing">rna sequencing</a></b> across arxiv, biorxiv, and medrxiv.</p>
                <div class="bg-white overflow-hidden shadow rounded-lg">
                  <div class="px-4 py-5 sm:p-6 content">
                    <h1 class="text-xl font-bold mt-0 mb-2">Welcome to Sympo Science!</h1>
                    <p>
                      Sympo Science enables you to instantly search open access articles across <a href="https://arxiv.org/">arxiv</a>, <a href="https://biorxiv.org/">biorxiv</a>, and <a href="https://medrxiv.org/">medrxiv</a>.
                    </p><br/>
                    <p>
                      Currently, sympo only indexes biologicial sciences articles from these preprint servers. Please join our <a href="https://discord.gg/VBpavYHvG6">Discord</a> channel to request additional pre-print servers/topics, report any bugs, or submit new feature requests.
                    </p><br/>
                    <p>
                      Enter anything in the search bar above to begin searching!
                    </p>
                  </div>
                </div>
              </template>
            </template>
          </ais-state-results>
        </div>
      </div>
    </ais-instant-search>
  </div>
</template>

<script>
import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter';
import { history } from 'instantsearch.js/es/lib/routers';
import { singleIndex as singleIndexMapping } from 'instantsearch.js/es/lib/stateMappings';
import { ArrowNarrowLeftIcon, ArrowNarrowRightIcon, SearchIcon } from '@heroicons/vue/solid'

const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
  server: {
    // apiKey: 'xyz', // Be sure to use an API key that only allows searches, in production
    apiKey: 'YYese3W8jPVmfymKhmiE8B3ZHW5OREoB', // Be sure to use an API key that only allows searches, in production
    nodes: [
      // {
      //   host: 'localhost',
      //   port: '8108',
      //   protocol: 'http',
      // },
      {
        host: 'search.sympo.io',
        port: '443',
        protocol: 'https',
      },
    ],
  },
  // The following parameters are directly passed to Typesense's search API endpoint.
  //  So you can pass any parameters supported by the search endpoint below.
  //  queryBy is required.
  //  filterBy is managed and overridden by InstantSearch.js. To set it, you want to use one of the filter widgets like refinementList or use the `configure` widget.
  additionalSearchParameters: {
    queryBy: 'title,abstract,authors',
    // groupBy: 'title',
    // groupLimit: 1
  },
});
const searchClient = typesenseInstantsearchAdapter.searchClient;

export default {
  name: 'Search',
  components: {
    ArrowNarrowLeftIcon,
    ArrowNarrowRightIcon,
    SearchIcon,
    // StarIcon,
  },
  data() {
    return {
      searchClient,
      routing: {
        router: history(),
        stateMapping: singleIndexMapping('papers'),
      },
    };
  },
  methods: {}
,
};
</script>

<style scoped>
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
}
</style>

<style>
.search-panel {
  display: flex;
}

.search-panel__filters {
  flex: 1;
}

.search-panel__results {
  flex: 3;
}

.ais-Highlight-highlighted {
  color: inherit;
  font-size: inherit;
}

.pagination {
  margin: 2rem auto;
  text-align: center;
}

.article-title {
  font-size: 1.1rem;
  font-weight: bold;
}

.hit-authors {
  margin-top: 3px;
  font-size: 0.8rem;
}

.hit-publication-year {
  font-size: 0.8rem;
  margin-top: 20px;
}

.hit-rating {
  margin-top: 3px;
  font-size: 0.8rem;
}

.ais-Hits-item {
  width: 100%;
  padding: 30px;
  box-shadow: none;
  border: 1px solid lighten(lightgray, 8%);
}

.author {
  font-weight: 500;
  cursor: default;
}

.author:hover {
  font-style: italic;
}

.header {
  color: #fe036e;
}

.content a {
  text-decoration: none;
  background-image: linear-gradient(transparent, transparent 5px, #c9cacc 5px, #c9cacc);
  background-position: bottom;
  background-size: 100% 6px;
  background-repeat: repeat-x;
}

.content a:hover {
  background-image: linear-gradient(transparent, transparent 5px, #ffbf0a 5px, #ffbf0a);
}

/*
.ais-SearchBox-input {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  padding: 0.75rem 3rem;
  font-size: 1.2rem;
  border-radius: 2em;
}

.ais-SearchBox-submit {
  left: 1rem;
}

.ais-SearchBox-reset {
  right: 1rem;
}
*/
</style>
