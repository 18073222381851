<template>
  <div>
    <Header/>
    <Search/>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
// import HeaderWithLogin from '@/components/HeaderWithLogin.vue';
import Search from '@/components/Search.vue';

export default {
  name: 'SearchPage',
  components: {
    Header,
    Search,
  },
};
</script>

<style></style>
