import { createWebHistory, createRouter } from "vue-router";
import SearchPage from '@/pages/SearchPage';

const routes = [
  {
    path: "/",
    name: "search",
    component: SearchPage,
  },
  {
    path: "/bookmarks",
    name: "bookmarks",
    component: SearchPage,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;