<template>
  <!-- When the mobile menu is open, add `overflow-hidden` to the `body` element to prevent double scrollbars -->
  <Popover as="template" v-slot="{ open }">
    <header :class="[open ? 'fixed inset-0 z-40 overflow-y-auto' : '', 'bg-white shadow-sm lg:static lg:overflow-y-visible py-4']">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="relative flex justify-between xl:grid xl:grid-cols-12 lg:gap-8">
          <div class="flex md:absolute md:left-0 md:inset-y-0 lg:static xl:col-span-2">
            <div class="flex-shrink-0 flex items-center">
              <a href="#">
                <img class="block h-8 w-auto" src="@/assets/sympo-logo.png" alt="Sympo Logo" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </header>
  </Popover>
</template>

<script>
import { Popover } from '@headlessui/vue'

export default {
  components: {
    Popover,
  },
  setup() {
    return {}
  },
}
</script>

<!--
<template>
  <header class="header">
    <div class="container">
      <div class="main">
        <img src="@/assets/sympo-logo.png" class="logo"/>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'Header',
  data() {},
  methods: {},
};
</script>

<style scoped>
.header {
  display: flex;
  align-items: center;
  min-height: 50px;
  padding: 1rem 1rem;
}

.container {
  max-width: 70%;
  max-width: 1200px;
  margin: 0 auto;
  text-align: left;
}

.header a {
  text-decoration: none;
}

.header-title {
  font-size: 1.2rem;
  font-weight: normal;
}

.main {
  display: inline-block;
  width: 20%;
  text-align: left;
  padding: 0 1em;
  line-height: 1.5;
}

.logo {
  max-height: 3em;
}

</style>
-->