<template>
  <div class="bg-gray-50">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {},
};
</script>

<style>
html {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
body {
  margin: 0;
  min-width: fit-content;
}
</style>
